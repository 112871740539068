import React, { useState } from 'react'
import Layout from '../components/Layout';
import PaddedLayout from '../components/PaddedLayout';
import styled from "styled-components";

const Support = ({data}) => { 
    return(
        <Layout>
            <PaddedLayout>
                <div className="page-wrapper">
                <h1>Support</h1>
                <h3>Products</h3>
                <h4>Walk Behind Scrubbers</h4>
                <ul>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                </ul>
                <h4>Ride On Scrubbers</h4>
                <ul>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                </ul>
                <h4>Sweepers</h4>
                <ul>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                </ul>
                <h4>Burnishers</h4>
                <ul>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                    <li>Product1</li>
                </ul>
                </div>
            </PaddedLayout>

        </Layout>
    )
}

export default Support
